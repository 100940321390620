import * as React from 'react';
import { Icon } from '../icon/icon';

export const IconHelp: React.FC = () => (
    <Icon viewBox="0 0 48 48" fill="none">
        <path
            d="M18 18.0001C18.0002 16.9001 18.3028 15.8214 18.8746 14.8818C19.4465 13.9422 20.2656 13.1779 21.2426 12.6724C22.2194 12.1669 23.3166 11.9396 24.4138 12.0155C25.5112 12.0914 26.5666 12.4674 27.4646 13.1026C28.3626 13.7377 29.0688 14.6075 29.5058 15.6169C29.943 16.6262 30.0942 17.7363 29.943 18.8258C29.7918 19.9153 29.3442 20.9424 28.6488 21.7946C27.9534 22.6468 27.037 23.2914 26 23.658C25.4148 23.865 24.9082 24.2482 24.55 24.755C24.1918 25.262 23.9996 25.8674 24 26.488V28.5"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M24 36C23.5858 36 23.25 35.6642 23.25 35.25C23.25 34.8358 23.5858 34.5 24 34.5"
            stroke="currentColor"
            strokeWidth="3"
        />
        <path
            d="M24 36C24.4142 36 24.75 35.6642 24.75 35.25C24.75 34.8358 24.4142 34.5 24 34.5"
            stroke="currentColor"
            strokeWidth="3"
        />
        <path
            d="M24 46.5C36.4264 46.5 46.5 36.4264 46.5 24C46.5 11.5736 36.4264 1.5 24 1.5C11.5736 1.5 1.5 11.5736 1.5 24C1.5 36.4264 11.5736 46.5 24 46.5Z"
            stroke="currentColor"
            strokeWidth="3"
            strokeMiterlimit="10"
        />
    </Icon>
);
