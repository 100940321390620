import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    ({ theme }) => css`
        margin-bottom: ${theme.gap.medium};
        background: ${theme.palette.gradient.primary.main};
        padding: ${theme.gap.small};
        color: ${theme.palette.color.white.main};
        border-radius: ${theme.radius.large};
        box-shadow: ${theme.shadow.large};
        font-weight: 700;
        font-size: ${theme.text.size.medium.fontSize};
        line-height: ${theme.text.size.medium.lineHeight};
        gap: ${theme.gap.medium};
        display: flex;
        flex-direction: column;

        @media ${theme.breakpoints.md.min} {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        @media ${theme.breakpoints.lg.min} {
            font-size: ${theme.text.size.xlarge.fontSize};
            line-height: ${theme.text.size.xlarge.lineHeight};
            padding: ${theme.gap.medium};
        }

        button {
            white-space: nowrap;
            flex: 0;
        }
    `,
);

export const Content = styled.div`
    flex: 1;
`;
