import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper, Content } from './limited-trades-for-free-user.styled';
import { Button } from '../form/button/button';
import { useRootStore } from '../../../providers/root-store-provider';

export const LimitedTradesForFreeUser: React.FC = () => {
    const { t } = useTranslation();
    const { premiumDialogStore } = useRootStore();

    return (
        <Wrapper>
            <Content>{t(`pages.homepage.limitedTradesForFreeUser.text`)}</Content>
            <Button
                variant="positive"
                gradient
                shadow
                block
                size={{ xs: 'small', md: 'medium', lg: 'large' }}
                onClick={async () => {
                    premiumDialogStore.open();
                }}
            >
                {t(`pages.homepage.limitedTradesForFreeUser.button`)}
            </Button>
        </Wrapper>
    );
};
